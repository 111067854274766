// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-about-us-index-tsx": () => import("./../../../src/components/about-us/index.tsx" /* webpackChunkName: "component---src-components-about-us-index-tsx" */),
  "component---src-components-blog-list-index-tsx": () => import("./../../../src/components/blog/list/index.tsx" /* webpackChunkName: "component---src-components-blog-list-index-tsx" */),
  "component---src-components-blog-post-index-tsx": () => import("./../../../src/components/blog/post/index.tsx" /* webpackChunkName: "component---src-components-blog-post-index-tsx" */),
  "component---src-components-blog-search-index-tsx": () => import("./../../../src/components/blog/search/index.tsx" /* webpackChunkName: "component---src-components-blog-search-index-tsx" */),
  "component---src-components-blog-tag-list-index-tsx": () => import("./../../../src/components/blog/tagList/index.tsx" /* webpackChunkName: "component---src-components-blog-tag-list-index-tsx" */),
  "component---src-components-career-index-tsx": () => import("./../../../src/components/career/index.tsx" /* webpackChunkName: "component---src-components-career-index-tsx" */),
  "component---src-components-career-job-details-index-tsx": () => import("./../../../src/components/career/job-details/index.tsx" /* webpackChunkName: "component---src-components-career-job-details-index-tsx" */),
  "component---src-components-cases-adact-index-tsx": () => import("./../../../src/components/cases/adact/index.tsx" /* webpackChunkName: "component---src-components-cases-adact-index-tsx" */),
  "component---src-components-cases-anuel-index-tsx": () => import("./../../../src/components/cases/anuel/index.tsx" /* webpackChunkName: "component---src-components-cases-anuel-index-tsx" */),
  "component---src-components-cases-appetini-index-tsx": () => import("./../../../src/components/cases/appetini/index.tsx" /* webpackChunkName: "component---src-components-cases-appetini-index-tsx" */),
  "component---src-components-cases-areabutler-index-tsx": () => import("./../../../src/components/cases/areabutler/index.tsx" /* webpackChunkName: "component---src-components-cases-areabutler-index-tsx" */),
  "component---src-components-cases-backbone-index-tsx": () => import("./../../../src/components/cases/backbone/index.tsx" /* webpackChunkName: "component---src-components-cases-backbone-index-tsx" */),
  "component---src-components-cases-beyond-green-index-tsx": () => import("./../../../src/components/cases/beyond-green/index.tsx" /* webpackChunkName: "component---src-components-cases-beyond-green-index-tsx" */),
  "component---src-components-cases-brocoders-index-tsx": () => import("./../../../src/components/cases/brocoders/index.tsx" /* webpackChunkName: "component---src-components-cases-brocoders-index-tsx" */),
  "component---src-components-cases-condogenie-index-tsx": () => import("./../../../src/components/cases/condogenie/index.tsx" /* webpackChunkName: "component---src-components-cases-condogenie-index-tsx" */),
  "component---src-components-cases-converthero-index-tsx": () => import("./../../../src/components/cases/converthero/index.tsx" /* webpackChunkName: "component---src-components-cases-converthero-index-tsx" */),
  "component---src-components-cases-everypig-index-tsx": () => import("./../../../src/components/cases/everypig/index.tsx" /* webpackChunkName: "component---src-components-cases-everypig-index-tsx" */),
  "component---src-components-cases-everypig-logistic-index-tsx": () => import("./../../../src/components/cases/everypig-logistic/index.tsx" /* webpackChunkName: "component---src-components-cases-everypig-logistic-index-tsx" */),
  "component---src-components-cases-geriana-index-tsx": () => import("./../../../src/components/cases/geriana/index.tsx" /* webpackChunkName: "component---src-components-cases-geriana-index-tsx" */),
  "component---src-components-cases-gokada-index-tsx": () => import("./../../../src/components/cases/gokada/index.tsx" /* webpackChunkName: "component---src-components-cases-gokada-index-tsx" */),
  "component---src-components-cases-gym-club-index-tsx": () => import("./../../../src/components/cases/gym-club/index.tsx" /* webpackChunkName: "component---src-components-cases-gym-club-index-tsx" */),
  "component---src-components-cases-heypractice-index-tsx": () => import("./../../../src/components/cases/heypractice/index.tsx" /* webpackChunkName: "component---src-components-cases-heypractice-index-tsx" */),
  "component---src-components-cases-hoa-managment-index-tsx": () => import("./../../../src/components/cases/hoa-managment/index.tsx" /* webpackChunkName: "component---src-components-cases-hoa-managment-index-tsx" */),
  "component---src-components-cases-hypeboard-index-tsx": () => import("./../../../src/components/cases/hypeboard/index.tsx" /* webpackChunkName: "component---src-components-cases-hypeboard-index-tsx" */),
  "component---src-components-cases-i-fit-index-tsx": () => import("./../../../src/components/cases/i-fit/index.tsx" /* webpackChunkName: "component---src-components-cases-i-fit-index-tsx" */),
  "component---src-components-cases-index-tsx": () => import("./../../../src/components/cases/index.tsx" /* webpackChunkName: "component---src-components-cases-index-tsx" */),
  "component---src-components-cases-korobox-index-tsx": () => import("./../../../src/components/cases/korobox/index.tsx" /* webpackChunkName: "component---src-components-cases-korobox-index-tsx" */),
  "component---src-components-cases-ladderout-index-tsx": () => import("./../../../src/components/cases/ladderout/index.tsx" /* webpackChunkName: "component---src-components-cases-ladderout-index-tsx" */),
  "component---src-components-cases-mycurrency-index-tsx": () => import("./../../../src/components/cases/mycurrency/index.tsx" /* webpackChunkName: "component---src-components-cases-mycurrency-index-tsx" */),
  "component---src-components-cases-revenue-boosters-index-tsx": () => import("./../../../src/components/cases/revenue-boosters/index.tsx" /* webpackChunkName: "component---src-components-cases-revenue-boosters-index-tsx" */),
  "component---src-components-cases-skilent-index-tsx": () => import("./../../../src/components/cases/skilent/index.tsx" /* webpackChunkName: "component---src-components-cases-skilent-index-tsx" */),
  "component---src-components-cases-traders-alloy-index-tsx": () => import("./../../../src/components/cases/traders-alloy/index.tsx" /* webpackChunkName: "component---src-components-cases-traders-alloy-index-tsx" */),
  "component---src-components-coming-soon-index-tsx": () => import("./../../../src/components/coming-soon/index.tsx" /* webpackChunkName: "component---src-components-coming-soon-index-tsx" */),
  "component---src-components-feedback-thanks-index-tsx": () => import("./../../../src/components/feedback/thanks/index.tsx" /* webpackChunkName: "component---src-components-feedback-thanks-index-tsx" */),
  "component---src-components-fitness-app-calculator-index-tsx": () => import("./../../../src/components/fitness-app-calculator/index.tsx" /* webpackChunkName: "component---src-components-fitness-app-calculator-index-tsx" */),
  "component---src-components-industries-agtech-index-tsx": () => import("./../../../src/components/industries/agtech/index.tsx" /* webpackChunkName: "component---src-components-industries-agtech-index-tsx" */),
  "component---src-components-industries-edtech-index-tsx": () => import("./../../../src/components/industries/edtech/index.tsx" /* webpackChunkName: "component---src-components-industries-edtech-index-tsx" */),
  "component---src-components-industries-events-management-index-tsx": () => import("./../../../src/components/industries/events-management/index.tsx" /* webpackChunkName: "component---src-components-industries-events-management-index-tsx" */),
  "component---src-components-industries-fintech-index-tsx": () => import("./../../../src/components/industries/fintech/index.tsx" /* webpackChunkName: "component---src-components-industries-fintech-index-tsx" */),
  "component---src-components-industries-foodtech-index-tsx": () => import("./../../../src/components/industries/foodtech/index.tsx" /* webpackChunkName: "component---src-components-industries-foodtech-index-tsx" */),
  "component---src-components-industries-martech-index-tsx": () => import("./../../../src/components/industries/martech/index.tsx" /* webpackChunkName: "component---src-components-industries-martech-index-tsx" */),
  "component---src-components-industries-proptech-index-tsx": () => import("./../../../src/components/industries/proptech/index.tsx" /* webpackChunkName: "component---src-components-industries-proptech-index-tsx" */),
  "component---src-components-industries-ride-sharing-index-tsx": () => import("./../../../src/components/industries/ride-sharing/index.tsx" /* webpackChunkName: "component---src-components-industries-ride-sharing-index-tsx" */),
  "component---src-components-landing-pages-chat-gpt-index-tsx": () => import("./../../../src/components/landing-pages/chat-gpt/index.tsx" /* webpackChunkName: "component---src-components-landing-pages-chat-gpt-index-tsx" */),
  "component---src-components-landing-pages-developers-cv-index-tsx": () => import("./../../../src/components/landing-pages/developers-cv/index.tsx" /* webpackChunkName: "component---src-components-landing-pages-developers-cv-index-tsx" */),
  "component---src-components-landing-pages-fitness-app-api-integrations-index-tsx": () => import("./../../../src/components/landing-pages/fitness-app-api-integrations/index.tsx" /* webpackChunkName: "component---src-components-landing-pages-fitness-app-api-integrations-index-tsx" */),
  "component---src-components-landing-pages-fitness-center-app-index-tsx": () => import("./../../../src/components/landing-pages/fitness-center-app/index.tsx" /* webpackChunkName: "component---src-components-landing-pages-fitness-center-app-index-tsx" */),
  "component---src-components-landing-pages-fitness-mobile-solutions-version-a-index-tsx": () => import("./../../../src/components/landing-pages/fitness-mobile-solutions-version-A/index.tsx" /* webpackChunkName: "component---src-components-landing-pages-fitness-mobile-solutions-version-a-index-tsx" */),
  "component---src-components-landing-pages-fitness-mobile-solutions-version-b-index-tsx": () => import("./../../../src/components/landing-pages/fitness-mobile-solutions-version-B/index.tsx" /* webpackChunkName: "component---src-components-landing-pages-fitness-mobile-solutions-version-b-index-tsx" */),
  "component---src-components-landing-pages-swg-offer-index-tsx": () => import("./../../../src/components/landing-pages/swg-offer/index.tsx" /* webpackChunkName: "component---src-components-landing-pages-swg-offer-index-tsx" */),
  "component---src-components-partnership-index-tsx": () => import("./../../../src/components/partnership/index.tsx" /* webpackChunkName: "component---src-components-partnership-index-tsx" */),
  "component---src-components-policy-privacy-policy-index-tsx": () => import("./../../../src/components/policy/privacy-policy/index.tsx" /* webpackChunkName: "component---src-components-policy-privacy-policy-index-tsx" */),
  "component---src-components-policy-slavery-statement-index-tsx": () => import("./../../../src/components/policy/slavery-statement/index.tsx" /* webpackChunkName: "component---src-components-policy-slavery-statement-index-tsx" */),
  "component---src-components-policy-supply-chain-index-tsx": () => import("./../../../src/components/policy/supply-chain/index.tsx" /* webpackChunkName: "component---src-components-policy-supply-chain-index-tsx" */),
  "component---src-components-policy-terms-of-use-index-tsx": () => import("./../../../src/components/policy/terms-of-use/index.tsx" /* webpackChunkName: "component---src-components-policy-terms-of-use-index-tsx" */),
  "component---src-components-services-ai-development-index-tsx": () => import("./../../../src/components/services/ai-development/index.tsx" /* webpackChunkName: "component---src-components-services-ai-development-index-tsx" */),
  "component---src-components-services-dedicated-team-index-tsx": () => import("./../../../src/components/services/dedicated-team/index.tsx" /* webpackChunkName: "component---src-components-services-dedicated-team-index-tsx" */),
  "component---src-components-services-devops-index-tsx": () => import("./../../../src/components/services/devops/index.tsx" /* webpackChunkName: "component---src-components-services-devops-index-tsx" */),
  "component---src-components-services-digital-transformation-index-tsx": () => import("./../../../src/components/services/digital-transformation/index.tsx" /* webpackChunkName: "component---src-components-services-digital-transformation-index-tsx" */),
  "component---src-components-services-mobile-app-development-index-tsx": () => import("./../../../src/components/services/mobile-app-development/index.tsx" /* webpackChunkName: "component---src-components-services-mobile-app-development-index-tsx" */),
  "component---src-components-services-mvp-development-index-tsx": () => import("./../../../src/components/services/mvp-development/index.tsx" /* webpackChunkName: "component---src-components-services-mvp-development-index-tsx" */),
  "component---src-components-services-product-development-index-tsx": () => import("./../../../src/components/services/product-development/index.tsx" /* webpackChunkName: "component---src-components-services-product-development-index-tsx" */),
  "component---src-components-services-qa-index-tsx": () => import("./../../../src/components/services/qa/index.tsx" /* webpackChunkName: "component---src-components-services-qa-index-tsx" */),
  "component---src-components-services-saas-development-index-tsx": () => import("./../../../src/components/services/saas-development/index.tsx" /* webpackChunkName: "component---src-components-services-saas-development-index-tsx" */),
  "component---src-components-services-team-augmentation-index-tsx": () => import("./../../../src/components/services/team-augmentation/index.tsx" /* webpackChunkName: "component---src-components-services-team-augmentation-index-tsx" */),
  "component---src-components-services-web-application-development-index-tsx": () => import("./../../../src/components/services/web-application-development/index.tsx" /* webpackChunkName: "component---src-components-services-web-application-development-index-tsx" */),
  "component---src-components-technologies-blockchain-index-tsx": () => import("./../../../src/components/technologies/blockchain/index.tsx" /* webpackChunkName: "component---src-components-technologies-blockchain-index-tsx" */),
  "component---src-components-technologies-gatsby-js-index-tsx": () => import("./../../../src/components/technologies/gatsby-js/index.tsx" /* webpackChunkName: "component---src-components-technologies-gatsby-js-index-tsx" */),
  "component---src-components-technologies-node-js-index-tsx": () => import("./../../../src/components/technologies/node-js/index.tsx" /* webpackChunkName: "component---src-components-technologies-node-js-index-tsx" */),
  "component---src-components-technologies-react-js-index-tsx": () => import("./../../../src/components/technologies/react-js/index.tsx" /* webpackChunkName: "component---src-components-technologies-react-js-index-tsx" */),
  "component---src-components-technologies-react-native-index-tsx": () => import("./../../../src/components/technologies/react-native/index.tsx" /* webpackChunkName: "component---src-components-technologies-react-native-index-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-components-header-contacts-tsx": () => import("./../../../src/pages/components/header/contacts.tsx" /* webpackChunkName: "component---src-pages-components-header-contacts-tsx" */),
  "component---src-pages-components-header-index-tsx": () => import("./../../../src/pages/components/header/index.tsx" /* webpackChunkName: "component---src-pages-components-header-index-tsx" */),
  "component---src-pages-components-header-wave-index-tsx": () => import("./../../../src/pages/components/header/wave/index.tsx" /* webpackChunkName: "component---src-pages-components-header-wave-index-tsx" */),
  "component---src-pages-components-ts-index-tsx": () => import("./../../../src/pages/components/ts/index.tsx" /* webpackChunkName: "component---src-pages-components-ts-index-tsx" */),
  "component---src-pages-components-work-proccess-index-tsx": () => import("./../../../src/pages/components/work-proccess/index.tsx" /* webpackChunkName: "component---src-pages-components-work-proccess-index-tsx" */),
  "component---src-pages-get-in-touch-components-header-index-tsx": () => import("./../../../src/pages/get-in-touch/components/header/index.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-components-header-index-tsx" */),
  "component---src-pages-get-in-touch-components-layout-tsx": () => import("./../../../src/pages/get-in-touch/components/layout.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-components-layout-tsx" */),
  "component---src-pages-get-in-touch-index-tsx": () => import("./../../../src/pages/get-in-touch/index.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

